// Table of Content
// ===============================================
// # Variables
// # Elements */

// # Variables
// =============================================== */
// No local variables available

// # Elements
// =============================================== */

.feature {
	background: $color-gray;
	@include margin(rem(-90px) null null);
	@include padding(rem(145px) null rem(50px));
	@include media($breakpoint-desktop) {
		@include padding(rem(120px) null rem(50px));
	}

	> div {
		@include position(relative);
	}

	h2 {
		font-family: $heading-font-family;
		@include font-size($font-size-h2);
		@include padding(null null null rem(40px));
		@include position(absolute, rem(-100px) 15px null null);
		@include background-image(arrow-down2, png, left rem(15px), no-repeat, rem(32px));
		@include media($breakpoint-desktop) {
			@include position(static);
			@include margin(null null rem(40px));
		}
	}

	section {
		overflow: hidden;
		@include position(relative);
		@include margin(null null rem(30px));
		&:after {
			content: "";
			width: 150px;
			height: 60px;
			display: block;
			background: $color-gray;
			border-top: 5px solid transparent;
			@include transform(rotate(-45deg));
			@include position(absolute, null -70px -25px null);
		}
		div {
			overflow: hidden;
			background: $color-white;
			border: 5px solid transparent;
			@include position(relative);
			@include padding(rem(35px) rem(25px));
		}

		h3 {
			font-family: $heading-font-family;
			@include margin(null null rem(5px));
			@include font-size($font-size-h3);
		}

		span {
			display: block;
			@include font-size(18px);
			@include position(relative);
			@include padding(null null rem(3px) rem(35px));

			i {
				color: $text-color-gray;
				@include position(absolute, rem(4px) null null 0);
			}

		}

		hr {
			border-color: rgba($color-black, 0.2);
			@include margin(rem(13px) null);
		}

		aside {
			@include padding(null rem(130px) null null);
			@include position(relative);

			.btn {
				@include position(absolute, null rem(30px) 0 null);
			}

		}

		&:hover {
			&:after {
				border-color: $color-primary;
			}

			div {
				border-color: $color-primary;
			}

		}

	}

}