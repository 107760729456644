// Table of Content
// ===============================================
// # Variables
// # Elements */

// # Variables
// =============================================== */
// No local variables available

// # Elements
// =============================================== */

.subscribe {
	z-index: 1;
	height: 170px;
	color: $color-white;
	@include position(relative);
	@include margin(rem(-85px) null 0);
	@include media($breakpoint-tablet) {
		height: 160px;
	}
	@include media($breakpoint-phone) {
		height: auto;
		@include margin(0 null null);
	}
	&:before {
		z-index: -1;
		content: "";
		width: 50%;
		height: 100%;
		display: block;
		background: $color-black;
		border-top: rem(5px) solid $color-white;
		border-bottom: rem(5px) solid $color-white;
		@include position(absolute, 0 null null 0);
		@include media($breakpoint-desktop) {
			width: 58%;
		}
		@include media($breakpoint-phone) {
			width: 100%;
		}

	}
	&:after {
		content: "";
		width: 78px;
		height: 100%;
		display: block;
		@include position(absolute, 0 null null 50%);
		@include background-image(subscribe-shape, png, center right, no-repeat, auto 100%);
		@include media($breakpoint-desktop) {
			@include position(absolute, 0 null null 58%);
		}
		@include media($breakpoint-tablet) {
			background-size: auto 161px;
		}
		@include media($breakpoint-phone) {
			display: none;
		}

	}

	> div {
		@include position(relative);
	}

	h5 {
		font-family: 'rabioheadregular', Arial, sans-serif;
		@include font-size(40px);
		@include padding(null rem(50px) null rem(15px));
		@include position(absolute, null null 110% 0);
		@include background-image(arrow-down, png, right rem(20px), no-repeat, rem(32px));
		@include media($breakpoint-phone) {
			@include font-size(35px);
			@include position(absolute, null null 100% 0);
			@include background-image(arrow-down, png, right rem(10px), no-repeat, rem(22px));
		}

	}

	form {
		height: rem(60px);
		@include clearfix;
		@include position(relative);
		@include margin(rem(55px) null null);
		@include padding(null rem(98px) null null);
		@include media($breakpoint-phone) {
			height: auto;
			@include margin(0);
			@include padding(0);
		}

		.wpcf7-form-control-wrap {
			width: 50%;
			float: left;
			@include padding(null rem(15px));
			@include media($breakpoint-phone) {
				width: 100%;
				float: none;
			}
			&:first-of-type {
				@include padding(null null null 0);
			}

			input {
				height: rem(60px);
				border: 2px solid transparent;
				@include font-size(20px);
				@include border-top-radius(4px);
				@include border-bottom-radius(4px);
				@include padding(rem(15px) rem(20px));
				@include placeholder($text-color-base);

				&.wpcf7-not-valid {
					border-color: $color-error;
					@include placeholder($color-error);
					&:focus {
						@include placeholder($text-color-base);
					}

				}

			}

		}

		div[role="alert"],
		span[role="alert"] {
			display: none !important;
		}

		button[type="submit"] {
			border: 0;
			z-index: 1;
			width: rem(82px);
			height: rem(60px);
			@include font-size(22px);
			@include position(absolute, 0 0 null null);
			@include media($breakpoint-phone) {
				float: right;
				display: block;
				@include position(relative);
				@include margin(rem(20px) null);
			}
			&:hover {
				background-color: transparent;
			}

		}

	}

}
