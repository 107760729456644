// Table of Content
// ===============================================
// # Variables
// # Elements */

// # Variables
// =============================================== */
// No local variables available

// # Elements
// =============================================== */

.banner {
	width: 100%;
	display: table;
	text-align: right;
	min-height: 370px;
	color: $color-white;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center top;
	@include padding(rem(198px) null rem(50px));
	@include media($breakpoint-desktop) {
		min-height: 300px;
		@include padding(rem(138px) null rem(50px));
	}
	@include media($breakpoint-phone) {
		text-align: left;
		@include padding(rem(100px) null rem(50px));
	}

	section {
		display: table-cell;
		vertical-align: middle;

		h1 {
			font-family: $heading-font-family;
			@include font-size($font-size-h1);
			@include margin(null null rem(3px));
		}

		p {
			text-align: left;
			display: inline-block;
			@include font-size(22px);

			a {
				color: $text-color-gray;
				&:hover {
					color: $color-white;
				}

			}

			i {
				@include margin(null rem(7px));
			}

		}

	}

}