// Table of Content
// ===============================================
// # Variables
// # Elements */

// # Variables
// =============================================== */
// No local variables available

// # Elements
// =============================================== */

.article {

	h2 {
		font-family: $heading-font-family;
		@include font-size(48px);
	}

	.article__contact {
		@include padding(rem(10px) null rem(80px));

		p {
			color: $text-color-gray;
		}

		h2 {
			@include margin(null null rem(28px));
		}

		h3 {
			color: $text-color-primary;
			font-family: $heading-font-family;
			@include font-size(30px);
			@include margin(null null rem(45px));

		}

		h5 {
			line-height: 1.5;
			font-weight: 400;
			font-family: $heading-font-family;
			@include font-size(20px);
			@include margin(null null rem(30px));

			p {
				color: $text-color-base;
			}

		}

		ul {
			@include margin(null null rem(40px));

			li {
				color: $text-color-gray;
				border-bottom: 1px solid rgba($color-black, 0.1);
				@include position(relative);
				@include padding(rem(5px) null rem(5px) rem(30px));
				&:before {
					content: "\f061";
					color: $color-primary;
					font-family: FontAwesome;
					@include position(absolute, rem(5px) null null 0);
				}

				&:hover {
					color: $color-black;
					&:before {
						color: $color-black;
					}
				}

			}

		}

		form {
			@extend .forms;
		}

		.article__contact__info {
			border: 3px solid $color-primary;
			@include margin(null null rem(35px));

			ul {
				max-width: 980px;
				@include clearfix;
				@include margin(0 auto);
				@include padding(rem(30px) null);

				li {
					float: left;
					width: 33.3%;
					border: 0 none;
					@include font-size(18px);
					@include padding(0 rem(20px));
					@include media($breakpoint-desktop) {
						float: none;
						width: 100%;
						@include margin(null null rem(20px));
					}
					&:before {
						display: none;
					}
					&:last-child {
						@include media($breakpoint-desktop) {
							@include margin(0);
						}

					}

					h3 {
						color: $text-color-base;
						font-family: $heading-font-family;
						@include margin(0);
						@include font-size(24px);

						i {
							@include margin(null rem(10px) null null);
						}

					}

					span {
						color: $text-color-base;
					}

				}

			}

		}

		.locationmap {
			@include margin(null null rem(50px));

			.maps {
				height: rem(360px);
			}

		}

	}

	.article__vacancy {

		h2 {
			@include margin(null null rem(28px));
		}

		nav {
			@include margin(rem(20px) null);

			a, span {
				display: inline-block;
				@extend .button;
			}

			span {
				color: $color-white;
				background: $color-black;
			}

		}

		span {
			display: block;
			text-align: right;
			@include padding(rem(10px) null null);
			@include media($breakpoint-tablet) {
				text-align: left;
				@include padding(0);
			}

		}

		select {
			@include media($breakpoint-tablet) {
				@include margin(rem(10px) null rem(20px));
			}
		}

		section {
			z-index: 1;
			overflow: hidden;
			background: $color-gray;
			@include position(relative);
			@include margin(null null rem(30px));
			@include padding(rem(30px) rem(300px) rem(20px) rem(40px));
			@include media($breakpoint-desktop) {
				@include padding(rem(30px));
			}
			&:after {
				z-index: -1;
				content: "";
				width: rem(100px);
				height: rem(50px);
				background: $color-white;
				@include transform(rotate(-45deg));
				@include position(absolute, null rem(-45px) rem(-35px) null);
			}

			h3 {
				font-family: $heading-font-family;
				@include font-size(24px);
			}

			span {
				text-align: left;
				color: $text-color-gray;
				@include font-size(16px);
				@include margin(null null rem(10px));
			}

			.btn {
				@include padding(null rem(20px));
				@include transform(translateY(-50%));
				@include position(absolute, 50% rem(50px) null null);
				@include media($breakpoint-desktop) {
					@include position(static);
					@include transform(translateY(0));
				}

			}

			&:hover {
				background-color: $color-primary;

				span {
					color: $color-white;
				}

			}

		}

	}

	.article__content {
		@include padding(null null rem(50px));

		p {
			color: $text-color-gray;
			strong {
				color: $text-color-base;
			}
		}

		h3 {
			color: $text-color-primary;
			font-family: $heading-font-family;
			@include font-size(30px);
			@include margin(null null rem(30px));

		}

		ul {
			@include margin(null null rem(40px));

			li {
				color: $text-color-gray;
				border-bottom: 1px solid rgba($color-black, 0.1);
				@include position(relative);
				@include padding(rem(5px) null rem(5px) rem(30px));
				&:before {
					content: "\f061";
					color: $color-primary;
					font-family: FontAwesome;
					@include position(absolute, rem(5px) null null 0);
				}

				&:hover {
					color: $color-black;
					&:before {
						color: $color-black;
					}
				}

			}

		}

		form {
			@extend .forms;
		}

	}

}