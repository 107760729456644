@font-face {
    font-family: 'gasparbold_italic';
    src: url('../fonts/gaspar/gaspar_bold_italic-webfont.eot');
    src: url('../fonts/gaspar/gaspar_bold_italic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/gaspar/gaspar_bold_italic-webfont.woff2') format('woff2'),
         url('../fonts/gaspar/gaspar_bold_italic-webfont.woff') format('woff'),
         url('../fonts/gaspar/gaspar_bold_italic-webfont.ttf') format('truetype'),
         url('../fonts/gaspar/gaspar_bold_italic-webfont.svg#gasparbold_italic') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'gasparbold';
    src: url('../fonts/gaspar/gaspar_bold-webfont.eot');
    src: url('../fonts/gaspar/gaspar_bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/gaspar/gaspar_bold-webfont.woff2') format('woff2'),
         url('../fonts/gaspar/gaspar_bold-webfont.woff') format('woff'),
         url('../fonts/gaspar/gaspar_bold-webfont.ttf') format('truetype'),
         url('../fonts/gaspar/gaspar_bold-webfont.svg#gasparbold') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'gasparitalic';
    src: url('../fonts/gaspar/gaspar_regular_italic-webfont.eot');
    src: url('../fonts/gaspar/gaspar_regular_italic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/gaspar/gaspar_regular_italic-webfont.woff2') format('woff2'),
         url('../fonts/gaspar/gaspar_regular_italic-webfont.woff') format('woff'),
         url('../fonts/gaspar/gaspar_regular_italic-webfont.ttf') format('truetype'),
         url('../fonts/gaspar/gaspar_regular_italic-webfont.svg#gasparitalic') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'gasparregular';
    src: url('../fonts/gaspar/gaspar_regular-webfont.eot');
    src: url('../fonts/gaspar/gaspar_regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/gaspar/gaspar_regular-webfont.woff2') format('woff2'),
         url('../fonts/gaspar/gaspar_regular-webfont.woff') format('woff'),
         url('../fonts/gaspar/gaspar_regular-webfont.ttf') format('truetype'),
         url('../fonts/gaspar/gaspar_regular-webfont.svg#gasparregular') format('svg');
    font-weight: normal;
    font-style: normal;

}