// Table of Content
// ===============================================
// # Variables
// # Elements */

// # Variables
// =============================================== */
// No local variables available

// # Elements
// =============================================== */

.header {
	z-index: 99;
	width: 100%;
	background: $color-primary;
	@include transition(0.3s);
	@include position(fixed, 0 null null 0);
	@include media($breakpoint-phone) {
		background: $color-white;
	}

	aside {
		background-color: $color-white;
		@include clearfix;
		@include transition(0.3s);
		@include position(relative);
		@include padding(rem(11px) rem(28px) rem(20px) null);
		@include margin(null rem(-28px) null null);
		@include background-image(header-shape, svg, right, no-repeat, auto 101%);
		@include media($breakpoint-desktop-large) {
			@include padding(rem(11px) rem(28px) rem(15px) null);
		}
		@include media($breakpoint-desktop) {
			max-width: rem(500px);
			@include padding(rem(10px) rem(60px) rem(10px) null);
		}
		@include media($breakpoint-tablet) {
			@include padding(rem(10px) rem(40px) rem(10px) null);
		}
		@include media($breakpoint-phone) {
			background-image: none;
		}

		&:before {
			content: "";
			height: 100%;
			width: 2000px;
			display: block;
			background: $color-white;
			@include position(absolute, 0 100% null null);
		}

	}

	.header__logo {
		float: left;
		@include transition(0.3s);
		@include padding(rem(9px) null null);
		@include media($breakpoint-desktop-large) {
			max-width: 215px;
			@include padding(rem(5px) null null);
		}
		@include media($breakpoint-desktop) {
			@include padding(0);
		}

		a {

			img {
				@include transition(0.3s);
				@include media($breakpoint-desktop) {
					display: none;
				}

				&:last-child {
					max-width: 0;
					max-height: 0;
					@include media($breakpoint-desktop) {
						display: block;
						max-width: inherit;
						max-height: inherit;
					}

				}

			}

		}

	}

	.header__jobs {
		width: 50%;
		float: right;
		@include transition(0.3s);
		@include padding(rem(35px) null null);
		@include media($breakpoint-desktop-large) {
			width: 57%;
			@include padding(rem(25px) null null);
		}
		@include media($breakpoint-desktop) {
			width: 75%;
			@include padding(rem(15px) null null);
		}
		@include media($breakpoint-tablet) {
			width: 72%;
			@include padding(rem(18px) null null);
		}
		@include media($breakpoint-phone) {
			display: none;
		}

		> ul {
			@include margin(0);

			> li {
				float: left;
				@include position(relative);
				@include margin(null rem(30px));
				@include media($breakpoint-tablet) {
					@include margin(null rem(25px));
				}
				&:before {
					content: "";
					display: block;
					width: rem(2px);
					height: rem(18px);
					background: rgba($color-black, 0.3);
					@include position(absolute, rem(8px) null null rem(-30px));
					@include media($breakpoint-tablet) {
						height: rem(15px);
						@include position(absolute, rem(10px) null null rem(-25px));
					}

				}

				&:first-child {
					@include margin(null null null 0);

					&:before {
						display: none;
					}

				}

				> a {
					color: $text-color-base;
					font-family: $font-family-base-bold;
					@include font-size(22px);
					@include position(relative);
					@include media($breakpoint-tablet) {
						@include font-size(20px);
					}

					span {
						font-size: 58%;
						display: block;
						width: rem(22px);
						height: rem(22px);
						text-align: center;
						line-height: rem(22px);
						background: $color-primary;
						@include border-top-radius(50%);
						@include border-bottom-radius(50%);
						@include position(absolute, null null rem(15px) 100%);
					}

					i {
						@include margin(null null null rem(5px));
					}

				}

				&:last-child {
					@include margin(null 0 null null);

					> a {
						font-family: $font-family-base;
					}

				}

				> ul {
					z-index: 2;
					display: none;
					min-width: 265px;
					@include transform(translateX(-50%));
					@include padding(rem(18px) rem(20px));
					@include position(absolute, 173% null null 50%);
					@include background-image(menu-bg, svg, center bottom, no-repeat, 101%);
					&:before {
						z-index: -1;
						content: "";
						width: 100%;
						height: 70%;
						display: block;
						background: $color-primary;
						@include position(absolute, 0 null null 0);
					}
					&:after {
						content: "";
						width: 100%;
						display: block;
						height: rem(27px);
						background: transparent;
						@include position(absolute, null null 100% 0);
						@include background-image(menu-caret, svg, center bottom, no-repeat, 25px);
					}

					> li {
						border-bottom: 1px solid rgba($color-black, 0.2);
						@include padding(null null rem(2px));
						@include margin(null null rem(2px));
						&:last-child {
							border-bottom: 0 none;
						}

						> a {
							color: $text-color-base;
							@include font-size(18px);
						}

					}

				}

				&:hover {

					> ul {
						display: block;
					}

				}

			}

		}

	}

	.header__info {
		text-align: right;
		@include transition(0.3s);
		@include padding(rem(11px) null null);
		@include media($breakpoint-desktop) {
			display: none;
		}

		> ul {
			@include margin(0);

			> li {
				display: inline-block;
				@include font-size(18px);
				@include padding(null rem(10px) null null);

				i {
					color: $color-white;
					@include margin(null rem(13px) null null);
				}

				a {
					color: $text-color-base;
					&:hover {
						color: $color-white;
					}
				}

				&:last-child {
					@include position(relative);
					@include padding(null 0 null rem(5px));

					> a {
						display: block;
						width: rem(40px);
						height: rem(40px);
						text-align: center;
						color: $color-white;
						background: $color-black;
						@include font-size(16px);
						@include border-top-radius(50%);
						@include border-bottom-radius(50%);

						i {
							line-height: rem(40px);
							@include margin(0);
						}

					}

					> div {
						z-index: 2;
						width: 280px;
						display: none;
						background: $color-white;
						@include border-top-radius(4px);
						@include border-bottom-radius(4px);
						@include padding(rem(10px) rem(10px));
						@include position(absolute, 130% 0 null null);
						&:before {
							content: "";
							display: inline-block;
							border: 8px solid transparent;
							border-bottom-color: $color-white;
							@include position(absolute, null rem(12px) 100% null);
						}

						form {
							height: rem(40px);
							@include position(relative);

							input[type="text"] {
								border-width: 1px;
								height: rem(40px);
								@include margin(0);
								@include border-left-radius(4px);
							}

							input[type="submit"] {
								@extend .btn;
								@extend .btn-black;
								z-index: 1;
								height: 100%;
								border: 0 none;
								background: $color-black;
								@include font-size(18px);
								@include border-left-radius(0);
								@include padding(rem(8px) rem(15px));
								@include position(absolute, 0 0 null null);
							}

						}

					}

				}

			}

		}

	}

	.header__menu {
		text-align: right;
		@include padding(rem(23px) null null);
		@include media($breakpoint-desktop-large) {
			@include padding(rem(5px) null null);
		}
		@include media($breakpoint-desktop) {
			@include padding(rem(24px) null null);
		}
		@include media($breakpoint-tablet) {
			@include padding(rem(28px) null null);
		}
		@include media($breakpoint-phone) {
			float: right;
		}

		> ul {
			display: inline-block;
			@include margin(0);
			@include media($breakpoint-phone) {
				display: none;
			}

			> li {
				display: inline-block;
				@include position(relative);
				@include padding(null rem(27px));
				@include media($breakpoint-desktop) {
					@include padding(null rem(15px));
				}
				&:first-child {
					@include padding(null null null 0);
				}
				&:last-child {

					&:after {
						@include media($breakpoint-tablet) {
							display: none;
						}

					}

				}
				&:after {
					content: "";
					display: block;
					width: rem(2px);
					height: rem(18px);
					background: rgba($color-black, 0.2);
					@include position(absolute, rem(8px) rem(-3px) null null);
				}

				> a {
					font-family: $font-family-base-bold;
					@include font-size(22px);
					&:hover {
						color: $color-white;
					}
				}

			}

		}

		> ol {
			display: inline-block;
			@include margin(0);
			@include padding(null null null rem(24px));
			@include media($breakpoint-desktop) {
				@include padding(null null null rem(15px));
			}
			@include media($breakpoint-tablet) {
				display: none;
			}

			> li {
				display: inline-block;
				@include padding(null rem(6px));
				@include media($breakpoint-desktop) {
					@include padding(null rem(4px));
				}
				&:first-child {
					@include padding(null null null 0);
				}
				&:last-child {
					@include padding(null 0 null null);
				}

				> a {
					@include font-size(22px);
					&:hover {
						color: $color-white;
					}
				}

			}

		}

		.header__menu__search {
			float: right;
			display: none;
			width: rem(40px);
			height: rem(40px);
			text-align: center;
			color: $color-white;
			background: $color-black;
			@include font-size(16px);
			@include border-top-radius(50%);
			@include border-bottom-radius(50%);
			@include margin(rem(-4px) null null rem(15px));
			@include media($breakpoint-desktop) {
				display: block;
			}
			@include media($breakpoint-phone) {
				@include margin(rem(4px) null null rem(15px));
			}

			i {
				line-height: rem(40px);
				@include margin(0);
			}

		}

		.header__menu__opener {
			width: 27px;
			float: right;
			display: none;
			@include padding(7px null);
			@include position(relative);
			@include margin(rem(12px) null null rem(35px));
			@include media($breakpoint-phone) {
				display: block;
			}
			&:before {
				content: "";
				height: 3px;
				width: 100%;
				display: block;
				background: $color-black;
				@include transition(0.3s);
				@include position(absolute, 0 null null 0);
			}

			&:after {
				content: "";
				height: 3px;
				width: 100%;
				display: block;
				background: $color-black;
				@include transition(0.3s);
				@include position(absolute, null null 0 0);
			}

			span {
				width: 100%;
				height: 3px;
				display: block;
				background: $color-black;
				@include transition(0.3s);
			}

			&.header__menu__opener--active {

				&:before {
					top: 7px;
					transform: rotate(45deg);
				}
				&:after {
					bottom: 7px;
					transform: rotate(-45deg);
				}

				span {
					opacity: 0;
				}

			}

		}

		.header__menu__finder {
			z-index: 2;
			width: 280px;
			display: none;
			background: $color-white;
			@include border-top-radius(4px);
			@include border-bottom-radius(4px);
			@include padding(rem(10px) rem(10px));
			@include position(absolute, 140% 15px null null);
			@include media(min-width 992px) {
				display: none !important;
			}
			@include media($breakpoint-phone) {
				background: $color-primary;
			}
			&:before {
				content: "";
				display: inline-block;
				border: 8px solid transparent;
				border-bottom-color: $color-white;
				@include position(absolute, null rem(12px) 100% null);
				@include media($breakpoint-phone) {
					border-bottom-color: $color-primary;
					@include position(absolute, null rem(82px) 100% null);
				}
			}

			form {
				height: rem(40px);
				@include position(relative);
				@include media($breakpoint-phone) {
					height: rem(60px);
				}
				input[type="text"] {
					border-width: 1px;
					height: rem(40px);
					@include margin(0);
					@include border-left-radius(4px);
					@include media($breakpoint-phone) {
						height: rem(60px);
					}
				}

				input[type="submit"] {
					@extend .btn;
					@extend .btn-black;
					z-index: 1;
					height: 100%;
					border: 0 none;
					background: $color-black;
					@include font-size(18px);
					@include border-left-radius(0);
					@include padding(rem(8px) rem(15px));
					@include position(absolute, 0 0 null null);
				}

			}

		}

	}

	&.fixed {

		aside {
			max-width: rem(500px);
			@include padding(rem(10px) rem(60px) rem(10px) null);
			@include media($breakpoint-tablet) {
				@include padding(rem(10px) rem(40px) rem(10px) null);
			}

		}

		.header__logo {
			@include padding(0);

			a {

				img {
					max-width: 0;
					max-height: 0;

					&:last-child {
						max-width: inherit;
						max-height: inherit;
					}

				}

			}

		}

		.header__info {
			max-height: 0;
			overflow: hidden;
			@include padding(0);
		}

		.header__jobs {
			width: auto;
			@include padding(rem(15px) null null);
			@include media($breakpoint-desktop) {
				width: 75%;
			}
			@include media($breakpoint-tablet) {
				width: 72%;
				@include padding(rem(18px) null null);
			}

			> ul {

				> li {

					> ul {
						top: 145%;
					}

				}

			}

		}

		.header__menu {
			@include padding(rem(24px) null null);
			@include media($breakpoint-tablet) {
				@include padding(rem(28px) null null);
			}
		}

	}

}
