// Table of Content
// ===============================================
// # Variables
// # Elements */

// # Variables
// =============================================== */
// No local variables available

// # Elements
// =============================================== */

.sidebar {

	section {
		@include margin(null null rem(50px));

		&.widget_nav_menu {

			h4 {
				background: $color-primary;
				@include font-size(22px);
				@include margin(null null 0);
				@include padding(rem(13px) rem(30px));
				&:after {
					float: right;
					content: "\f107";
					font-family: FontAwesome;
				}

			}

			div {

				> ul {
					@include margin(0);

					> li {
						border-bottom: 2px solid $color-white;

						> a {
							display: block;
							background: $color-gray;
							border-left: 5px solid transparent;
							@include font-size(20px);
							@include position(relative);
							@include padding(rem(11px) rem(20px) rem(10px) rem(42px));
							&:before {
								content: "\f105";
								font-family: FontAwesome;
								@include position(absolute, rem(11px) null null rem(30px));
							}

						}

						&:hover,
						&.current_page_item,
						&.current-page-ancestor {

							a {
								color: $text-color-base;
								border-left-color: $color-primary
							}

						}

						> ul {
							@include margin(0);

							> li {

								> a {
									display: block;
									border-bottom: 1px solid rgba($color-black, 0.2);
									@include font-size(16px);
									@include position(relative);
									@include padding(rem(13px) rem(20px) rem(13px) rem(65px));
									&:before {
										content: "\f105";
										font-family: FontAwesome;
										@include position(absolute, rem(11px) null null rem(50px));
									}

								}
								&:hover,
								&.current_page_item {

									a {
										color: $color-primary;
									}

								}

								&:last-child {

									> a {
										border-bottom: 0 none;
									}

								}

							}

						}

					}

				}

			}

		}

		&.widget_specialist {
			z-index: 1;
			text-align: right;
			background: $body-bg;
			min-height: rem(300px);
			@include position(relative);
			@include padding(rem(30px) rem(23px));

			p {
				text-align: left;
				line-height: 1.2;
				@include font-size(18px);
				@include margin(null null rem(33px));
			}

			span {
				text-align: center;
				min-width: rem(160px);
				display: inline-block;
				background: $color-white;
				font-family: $font-family-base-bi;
				@include font-size(20px);
				@include position(relative);
				@include border-top-radius(25px);
				@include border-bottom-radius(25px);
				@include padding(rem(8px) rem(20px));
				@include margin(null null rem(30px));
				&:after {
					content: "";
					display: block;
					width: rem(21px);
					height: rem(12px);
					@include position(absolute, 100% null null rem(10px));
					@include background-image(indicator, png, center, no-repeat, 100%);
				}
			}

			h4 {
				font-family: $heading-font-family;
				@include font-size(24px);
			}

			figure {
				z-index: -1;
				max-width: rem(195px);
				@include position(absolute, null null 0 0);
			}

			.button {
				color: $color-white;
				background: $color-black;
				@include padding(rem(15px) null);
				@include border-top-radius(4px);
				@include border-bottom-radius(4px);
				@include position(absolute, null rem(23px) rem(-23px) null);

				i {
					font-size: 70%;
					color: $color-primary;
					@include margin(null null null rem(10px));
				}

				&:hover {
					color: $color-primary;
				}

			}

		}

		&.widget_filter {

			h4 {
				background: $color-primary;
				@include font-size(22px);
				@include margin(null null rem(2px));
				@include padding(rem(13px) rem(15px) rem(13px) rem(20px));
				&:after {
					float: right;
					content: "\f107";
					font-family: FontAwesome;
				}

			}

			select {
				@include margin(null null rem(2px));
			}

		}

		&.widget_vacancy {

			h4 {
				background: $color-primary;
				@include font-size(22px);
				@include margin(null null 0);
				@include padding(rem(13px) rem(30px));
				&:after {
					float: right;
					content: "\f107";
					font-family: FontAwesome;
				}

			}

			> ul {
				@include margin(0);

				> li {
					display: block;
					background: $color-gray;
					border-left: 5px solid transparent;
					font-family: $font-family-base-bold;
					border-bottom: 2px solid $color-white;
					@include font-size(20px);
					@include position(relative);
					@include padding(rem(11px) rem(20px) rem(10px) rem(52px));
					i {
						color: $color-primary;
						@include position(absolute, rem(15px) null null rem(20px));
					}

					&:hover {
						color: $text-color-base;
						border-left-color: $color-primary
					}

				}

			}

		}

	}

}