// Table of Content
// ===============================================
// # Variables
// # Elements */

// # Variables
// =============================================== */
// No local variables available

// # Elements
// =============================================== */

.slider {
	color: $color-white;

	h1 {
		line-height: 0.98;
		font-family: $font-family-base-bold;
		@include font-size($font-size-h1);
		@include margin(null null rem(30px));
		@include media($breakpoint-tablet) {
			@include font-size($font-size-h2);
		}
		@include media($breakpoint-phone) {
			@include font-size($font-size-h3);
		}

		span {
			color: $color-primary;
		}

	}

	.button {

		i {
			@include font-size(16px);
			@include margin(null null null rem(10px));
		}

	}

	.slick-slide {

		> div {
			width: 100%;
			height: 900px;
			display: table;
			background-size: cover;
			background-position: center;
			background-repeat: no-repeat;
			@include media($breakpoint-desktop) {
				height: 680px;
			}
			@include media($breakpoint-tablet) {
				height: 550px;
			}
			@include media($breakpoint-phone) {
				height: 450px;
			}

			> aside {
				display: table-cell;
				vertical-align: middle;
				@include padding(rem(170px) rem(30px) rem(130px) null);
				@include media($breakpoint-tablet) {
					@include padding(rem(120px) null null);
				}
				@include media($breakpoint-phone) {
					@include padding(rem(100px) null rem(100px));
				}

			}

		}

	}

	.slick-dots {
		width: 1px;
		display: table;
		@include margin(0 -690px 0 0);
		@include transform(translateY(-50%));
		@include position(absolute, 58% 50% null null);
		@include media(max-width 1440px) {
			@include margin(0);
			@include position(absolute, null rem(40px) null null);
		}

		> li {
			height: rem(83px);
			@include position(relative);
			&:before {
				z-index: -1;
				content: "";
				width: 1px;
				height: rem(10px);
				background: #ff0;
				@include position(absolute, 0 null 0 50%);
				@include media($breakpoint-phone) {
					@include margin(null null null rem(-1px));
				}
			}
			&:first-child {

				&:before {
					display: none;
				}

			}

			&:last-child {
				height: auto;
				overflow: hidden;
				> button {

					&::before {
						display: none;
					}

				}

			}

			> button {
				z-index: 1;
				width: rem(23px);
				height: rem(23px);
				text-indent: -9999px;
				background: transparent;
				border: 2px solid transparent;
				@include position(relative);
				@include border-top-radius(50%);
				@include border-bottom-radius(50%);
				&:before {
					z-index: -1;
					content: "";
					width: 1px;
					height: rem(73px);
					background: $color-white;
					@include position(absolute, rem(10px) null null 50%);
				}
				&:after {
					z-index: -1;
					content: "";
					border: 0 none;
					width: rem(10px);
					height: rem(10px);
					background: $color-white;
					@include padding(0);
					@include border-top-radius(50%);
					@include border-bottom-radius(50%);
					@include margin(rem(-5px) null null rem(-5px));
					@include position(absolute, 50% null null 50%);
				}

			}

			&.slick-active {
				&:before {
					display: none;
				}

				> button {
					border-color: $color-primary;
					&:before {
						top: rem(20px);
					}
					&:after {
						background: $color-primary;
					}

				}

			}

		}

	}

}