// Table of Content
// ===============================================
// # Variables
// # Elements */

// # Variables
// =============================================== */
$button-padding-vertical: rem(13px) !default;
$button-padding-horizontal: rem(20px) !default;

$button-height: ((rem($font-size-base) * $line-height-base) + ( $button-padding-vertical * 2 )) !default;

$button-font-family: $font-family-base-bold !default;
$button-font-size: 22px !default;
$button-font-weight: 400 !default;

$button-color: $text-color-base !default;
$button-color-hover: $text-color-base !default;
$button-color-disabled: #999 !default;

$button-border-size: rem(0) !default;
$button-border-style: solid !default;
$button-border-color: #2e6da4 !default;
$button-border-color-hover: #286090 !default;
$button-border-color-disabled: #666 !default;
$button-border-radius: 2px !default;

$button-bg: $color-primary !default;
$button-bg-hover: darken($color-primary, 10%) !default;
$button-bg-disabled: #efefef !default;

// # Elements
// =============================================== */
.btn,
.button,
.button:link,
.button:visited {
	line-height: normal;
	display: inline-block;
	cursor: pointer;
	text-align: center;
	vertical-align: middle;
	font-family: $button-font-family;
	font-weight: $button-font-weight;
	color: $button-color;
	touch-action: manipulation;
	white-space: nowrap;
	border: $button-border-size $button-border-style $button-border-color;
	border-radius: $button-border-radius;
	background: $button-bg;
	@include transition(0.3s);
	@include margin(null null 0);
	@include padding($button-padding-vertical $button-padding-horizontal);
	@include user-select(none);
	@include font-size($button-font-size);

	&.button--success {
		border-color: #4cae4c;
		background: #5cb85c;

		&:hover,
		&[type='submit']:hover,
		&[type='reset']:hover,
		&[type='button']:hover,
		&:focus,
		&.focus {
			border-color: #4cae4c;
			background-color: #4cae4c;
		}
	}

	&:hover,
	&[type='submit']:hover,
	&[type='reset']:hover,
	&[type='button']:hover,
	&:focus,
	&.focus {
		color: $button-color-hover;
		text-decoration: none;
		border-color: $button-border-color-hover;
		background-color: $button-bg-hover;
	}

	&:active,
	&.active {
		background-image: none;
	}

	&:disabled,
	&.disabled {
		color: $button-color-disabled;
		background-color: $button-bg-disabled;
	}

	&.btn-white {
		color: $text-color-base;
		background: $color-white;

		i {
			font-size: 70%;
			@include margin(null null null rem(20px));
		}

	}

	&.btn-info {
		border: 0 none;
		background-color: transparent;
		@include border-top-radius(4px);
		@include padding(rem(17px) rem(15px));
		@include background-image(btn-bg-primary, svg, right, no-repeat, auto 100%);
		&:hover,
		&:active,
		&:focus,
		&:hover:focus,
		&:hover:active {
			outline: none;
			border: 0 none;
			box-shadow: none;
			-webkit-box-shadow: none;
			color: $text-color-base;
			background-color: transparent;
			@include background-image(btn-bg-primaryhvr, svg, right, no-repeat, auto 100%);
		}

	}

	&.btn-black {
		color: $color-white;
		background: $color-black;
		@include border-top-radius(4px);
		@include border-bottom-radius(4px);

		i {
			font-size: 70%;
			color: $color-primary;
			@include margin(null null null rem(10px));
		}

		&:hover {
			color: $color-primary;
			background: $color-black;
		}

	}

	&.btn-lg {
		@include padding(rem(17px) rem(34px));
	}

}
