// Table of Content
// ===============================================
// # Variables
// # Mixins */

// # Variables
// =============================================== */
// No local variables available

// # Mixins
// =============================================== */

@mixin arrow( $size: rem(10px), $direction: down, $color: inherit ) {
	display: block;
	width: 0;
	height: 0;
	content: '';
	border: ($size / 2) solid transparent;

	@if ($direction == down) {
		$direction: 'top';
	} @else if ($direction == up) {
		$direction: 'bottom';
	} @else if ($direction == left) {
		$direction: 'right';
	} @else if ($direction == right) {
		$direction: 'left';
	} @else {
		@warn '[@include arrow[-*](vars)] $direction can only accept the following values: up, down, left, right. Falling back to default (down)';
		$direction: 'top';
	}

	border-#{$direction}-color: $color;
}

@mixin background-image( $name, $extension: 'png', $position: left center, $repeat: no-repeat, $size: false ) {
	background-image: url(#{$image-path}/#{$name}.#{$extension});
	background-position: $position;
	background-repeat: $repeat;

	@if ($size) {
		background-size: $size;
	}

	@if ($retina) {
		@include hidpi {
			background-image: url(#{$image-path}/#{$name}#{$retina-suffix}.#{$extension});
		}
	}
}

@mixin font-size( $value ) {
	font-size: rem($value);
}

@mixin no-gutter-last-child {
	& > *:last-child {
		@include margin(null null 0);
	}
}

@mixin omega-reset( $nth, $context: null ) {
	@if ($context == null) {
		&:nth-child(#{$nth}) {
			@include margin(null flex-gutter() null null);
		}
	} @else {
		&:nth-child(#{$nth}) {
			@include margin(null flex-gutter($context) null null);
		}
	}

	&:nth-child(#{$nth}+1) {
		clear: none;
	}
}
