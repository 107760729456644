// Table of Content
// ===============================================
// # General
// # Grid
// # Typography */

// # General
// =============================================== */
// Proper REM calculation
$em-base: 16px; // Do not change this unless you know what you are doing

// Image Path
$image-path: '../../img' !default;

// Retina suffix
$retina: false !default;
$retina-suffix: '@2x' !default;

// Body
$body-bg: #f1f1f1 !default;

// Page
$page-bg: #fff !default;

// Width
$width-base: 1430px !default; // Mainly used for centering

// Gutters
$gutter-base: 30px !default;
$gutter-top: 0 !default;
$gutter-right: $gutter-base !default;
$gutter-bottom: $gutter-base !default;
$gutter-left: $gutter-base !default;
$gutter-side: $gutter-base !default; // Used when base width is reached
$gutter-side-tablet: ($gutter-base / 2) !default; // Used when base width is reached
$gutter-side-phone: ($gutter-base / 2) !default; // Used when base width is reached

// Colors
$color-error: #d9534f !default;
$color-success: #5cb85c !default;
$color-info: #5bc0de !default;
$color-primary: #cedb00 !default;
$color-primary-light: #fafbe5 !default;
$color-black: #000 !default;
$color-gray: #f9f9f9 !default;
$color-gray-alt: #f1f1f1 !default;
$color-gray-dark: #ccc !default;
$color-white: #fff !default;
$color-yellow: #e4eb72 !default;

// # Grid
// =============================================== */
// General
$grid-helpers: true !default;

// Columns
$grids-columns: 12; // Number of columns in the grid system
$gutter: 1em; // Padding, to be divided by two and applied to the left and right of all columns
$max-width: $width-base;

// Breakpoints
$screen-xs: 480px !default;
$screen-sm: 768px !default;
$screen-md: 992px !default;
$screen-lg: 1200px !default;
$screen-xlg: 1400px !default;
$breakpoint-small: max-width $screen-xs - 1 !default;
$breakpoint-phone: max-width $screen-sm - 1 !default;
$breakpoint-tablet: max-width $screen-md - 1 !default;
$breakpoint-desktop: max-width $screen-lg - 1 !default;
$breakpoint-desktop-large: max-width $screen-xlg - 1 !default;

// # Typography
// =============================================== */
$text-color-base: #000 !default;
$text-color-gray: #999 !default;
$text-color-primary: #353533 !default;
$link-color-base: #000 !default;
$link-decoration-base: none !default;
$link-hover-color-base: #cedb00 !default;
$link-hover-decoration-base: none !default;

$font-family-base: 'gasparregular', Arial, sans-serif !default;
$font-family-base-italic: 'gasparitalic', Arial, sans-serif !default;
$font-family-base-bold: 'gasparbold', Arial, sans-serif !default;
$font-family-base-bi: 'gasparbold_italic', Arial, sans-serif !default;
$font-size-base: 20px !default;
$font-size-h1: 60px !default;
$font-size-h2: 36px !default;
$font-size-h3: 30px !default;
$font-size-h4: 20px !default;
$font-size-h5: 15px !default;
$font-size-h6: 10px !default;

$line-height-base: 1.5 !default; // Always use integer no px or rem values

$heading-font-family: $font-family-base-bold !default;
$heading-font-weight: 400 !default;
$heading-line-height: 1 !default;
$heading-color: inherit !default;
