// Table of Content
// ===============================================
// # Variables
// # Elements */

// # Variables
// =============================================== */
// No local variables available

// # Elements
// =============================================== */

.about {
	line-height: 1.3;
	min-height: 600px;
	color: $color-white;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center top;
	@include padding(rem(100px) null);
	@include media($breakpoint-desktop) {
		min-height: 0;
	}

	p {
		&:last-of-type {
			@include margin(null null rem(40px));
		}
	}

	h2 {
		color: $color-primary;
		font-family: $font-family-base-bold;
		@include font-size(36px);
		@include margin(null null rem(35px));
	}

	.button {
		min-width: 240px;
	}

}
