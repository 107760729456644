// Table of Content
// ===============================================
// # Variables
// # Elements */

// # Variables
// =============================================== */
// No local variables available

// # Elements
// =============================================== */

.work {
	@include padding(rem(85px) null rem(68px));

	h2 {
		font-family: $heading-font-family;
		@include font-size($font-size-h2);
		@include margin(null null rem(30px));
	}

	.more {
		float: right;
	}

	ul {
		@include clearfix;
		@include margin(0 rem(-20px));

		li {
			width: 20%;
			float: left;
			@include padding(null rem(20px));
			@include margin(null null rem(30px));
			@include media($breakpoint-desktop) {
				width: 33.3%;
			}
			@include media($breakpoint-phone) {
				width: 50%;
			}
			@include media($breakpoint-small) {
				width: 100%;
				float: none;
			}

			> div {
				overflow: hidden;
				background: $color-primary;
				@include position(relative);
				@include border-top-radius(5px);
				@include border-bottom-radius(5px);

				a {
					width: 100%;
					height: 100%;
					display: block;
					overflow: hidden;
					text-indent: -999px;
					@include position(absolute, 0 null null 0);
				}

				img {
					opacity: 0.1;
				}

				span {
					width: 100%;
					display: block;
					text-align: center;
					font-family: $font-family-base-bold;
					@include font-size(24px);
					@include padding(null rem(15px));
					@include transform(translateY(-50%));
					@include position(absolute, 50% null null 0);
				}

			}

		}

	}

}