// Table of Content
// ===============================================
// # Variables
// # Elements */

// # Variables
// =============================================== */
// No local variables available

// # Elements
// =============================================== */

.client {
	text-align: center;
	background: $color-gray-alt;
	@include padding(rem(40px) null rem(60px));

	h2 {
		font-family: $heading-font-family;
		@include font-size($font-size-h2);
		@include margin(null null rem(40px));
	}

	.slick-slider {

		.slick-slide {
			@include padding(null rem(18px));
		}

	}

	figure {
		width: 100%;
		height: 120px;
		display: table;
		background: $color-white;
		@include border-top-radius(4px);
		@include border-bottom-radius(4px);

		span {
			display: table-cell;
			vertical-align: middle;

			img {
				max-height: 100%;
				@include margin(null auto);
			}

		}

	}

}