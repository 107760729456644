// Table of Content
// ===============================================
// # Variables
// # Elements */

// # Variables
// =============================================== */
// No local variables available

// # Elements
// =============================================== */

.footer {

	.footer__info {
		color: $color-white;
		background: $color-primary;
		@include padding(rem(24px) null);

		h3 {
			color: $text-color-base;
			font-family: $font-family-base-bold;
			@include font-size(24px);
			@include margin(null null rem(5px));
			@include media($breakpoint-desktop-large) {
				@include font-size(20px);
			}
			@include media($breakpoint-desktop) {
				@include font-size(18px);
			}
			i {
				@include margin(null rem(5px) null null);
			}

		}

		figure {

			img {

				@include media($breakpoint-phone) {
					@include margin(null auto rem(30px));
				}

			}

		}

		ul {
			@include clearfix;
			@include margin(rem(15px) null 0 rem(-50px));
			@include media($breakpoint-desktop) {
				@include margin(null rem(-20px) null null);
			}
			@include media($breakpoint-phone) {
				@include margin(0);
			}

			li {
				float: left;
				text-align: center;
				@include media($breakpoint-phone) {
					float: none;
					@include margin(null null rem(15px));
				}
				&:nth-child(1) {
					width: 40%;
					@include media($breakpoint-phone) {
						width: 100%;
					}
				}
				&:nth-child(2) {
					width: 30%;
					@include media($breakpoint-phone) {
						width: 100%;
					}
				}
				&:nth-child(3) {
					width: 30%;
					@include media($breakpoint-phone) {
						width: 100%;
					}
				}

				> div {
					text-align: left;
					display: inline-block;
					@include media($breakpoint-phone) {
						text-align: center;
					}
					span {
						display: block;
						line-height: normal;
						color: $text-color-base;
						@include font-size(18px);
					}

					a {
						&:hover {
							color: $text-color-base;
						}
					}

				}

			}

		}

		aside {
			text-align: right;
			@include padding(rem(20px) null null);
			@include media($breakpoint-desktop) {
				text-align: center;
			}
			@include media($breakpoint-phone) {
				@include padding(0);
			}
			span {
				color: $text-color-base;
				font-family: 'rabioheadregular', Arial, sans-serif;
				@include font-size(30px);
				@include padding(null rem(10px) null null);
				@include media($breakpoint-desktop) {
					@include font-size(28px);
					@include padding(null rem(5px) null null);
				}
				@include media($breakpoint-phone) {
					display: block;
					@include padding(0);
				}

			}

			a {
				@include font-size(30px);
				@include margin(null null null rem(2px));
				@include media($breakpoint-desktop) {
					@include margin(0);
					@include font-size(28px);
				}
				&:hover {
					color: $text-color-base;
				}

			}

		}

	}

	.footer__widgets {
		@include padding(rem(50px) null rem(25px));

		h4 {
			font-family: $heading-font-family;
			@include font-size(20px);
			@include margin(null null rem(40px));
			@include media($breakpoint-phone) {
				@include margin(null null rem(15px));
			}
		}

		.widget {
			@include margin(null null rem(30px));

			&.widget_nav_menu {
				@extend .col-sm-3;
				@include media($breakpoint-phone) {
					text-align: center;
				}

				ul {
					@include margin(0);

					li {

						a {
							@include font-size(16px);
						}

					}

				}

			}

			&.widget_jobmenu {
				@extend .col-sm-3;
				@include media($breakpoint-phone) {
					text-align: center;
				}

				ul {
					@include margin(0);

					li {

						a {
							display: inline-block;
							@include font-size(16px);
							@include position(relative);
							@include padding(null null null rem(13px));
							&:before {
								content: "\f105";
								color: $text-color-gray;
								font-family: FontAwesome;
								@include position(absolute, rem(-2px) null null 0);

							}

						}

					}

				}

			}

			&.widget_contact {
				overflow: hidden;
				text-align: right;
				background: $body-bg;
				@include position(relative);
				&:after {
					content: "";
					width: 150px;
					height: 60px;
					display: block;
					background: $color-white;
					border-top: 5px solid $color-primary;
					@include transform(rotate(-45deg));
					@include position(absolute, null -70px -25px null);
				}

				div {
					overflow: hidden;
					min-height: 267px;
					border: 5px solid $color-primary;
					@include position(relative);
					@include padding(null null null rem(220px));
					@include media(max-width 1399px) {
						@include padding(null null null rem(150px));
						min-height: 220px;
					}
					@include media($breakpoint-phone) {
						min-height: 185px;
					}

				}

				figure {
					z-index: 0;
					max-width: rem(230px);
					@include position(absolute, null null 0 0);
					@include media(max-width 1399px) {
						max-width: rem(180px);
					}
				}

				aside {
					z-index: 1;
					@include position(relative);
					@include padding(rem(30px) rem(40px) rem(20px) null);

					address {
						@include font-size(16px);
						@include media(max-width 1399px) {
							@include margin(null null rem(10px));
						}

					}

					strong {
						@include font-size(18px);
					}

				}

				h4 {
					@include margin(null null rem(20px));
					@include font-size(30px);
				}

			}

		}

	}

	.footer__copyright {
		background: $body-bg;
		@include font-size(16px);
		@include padding(rem(16px) null);
		@include media($breakpoint-desktop) {
			text-align: center;
		}

		img {
			display: inline-block;
		}

		p {
			color: $text-color-gray;
			@include margin(0);
			@include padding(rem(10px) null null);
			a {
				color: $text-color-base;
				&:hover {
					color: $color-primary;
				}
			}
			img {
				vertical-align: middle;
				@include margin(null rem(5px));
			}
		}

		nav {
			display: inline-block;
			vertical-align: middle;

			ul {
				@include margin(0);
				@include padding(null rem(25px) null null);
				@include media($breakpoint-desktop-large) {
					@include padding(0);
				}

				li {
					display: inline-block;
					@include position(relative);
					@include padding(null rem(10px));
					&:before {
						content: "";
						width: rem(1px);
						height: rem(16px);
						background: $text-color-gray;
						@include position(absolute, rem(5px) null null rem(-1px));
					}
					&:first-child {
						@include padding(null null null 0);
						&:before {
							display: none;
						}
					}
					&:last-child {
						@include padding(null 0 null null);
					}

					a {
						color: $text-color-gray;
						&:hover {
							color: $color-primary;
						}

					}

				}

			}

		}

		aside {
			text-align: right;
			@include media($breakpoint-desktop) {
				text-align: center;
			}

			img {
				@include media($breakpoint-desktop-large) {
					max-width: rem(170px);
				}

			}

		}

	}

}
