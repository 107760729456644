// Table of Content
// ===============================================
// # Variables
// # Elements */

// # Variables
// =============================================== */
// No local variables available

// # Elements
// =============================================== */

.forms {
	z-index: 1;
	overflow: hidden;
	background: $color-primary-light;
	@include position(relative);
	@include padding(rem(45px) rem(40px) rem(30px));
	&:after {
		z-index: -1;
		content: "";
		width: rem(200px);
		height: rem(150px);
		background: $color-white;
		@include transform(rotate(-45deg));
		@include position(absolute, null rem(-95px) rem(-75px) null);
	}

	.wpcf7-form-control {
		@include margin(null null rem(10px));

		&.wpcf7-not-valid {
			border-color: $color-error;
			@include placeholder($color-error);
			&:focus {
				@include placeholder($text-color-base);
			}

		}

	}

	span[role="alert"] {
		display: none !important;
	}

	h3 {
		@include margin(null null rem(30px));
	}

	p {
		color: $text-color-base;
		@include margin(null null rem(50px));
	}

	button {
		@include margin(rem(20px) null null null);
	}

	input[type='file'] {
		width: auto;
		display: inline-block;
	}

	label {
		&.file {
			width: rem(200px);
			height: rem(60px);
			overflow: hidden;
			border-radius: 3px;
			line-height: rem(60px);
			background: $color-yellow;
			@include position(relative);
			@include padding(0 rem(20px));
			&:before {
				content: "\f0fe";
				display: inline-block;
				color: $color-white;
				font: normal normal normal 15px/1 FontAwesome;
				@include margin(null rem(5px) null null);
			}
			> span {
				opacity: 0;
				display: block;
				overflow: hidden;
				@include position(absolute, 0);
				input {
					@include margin(0);
				}
			}
		}
		&:not(.radio):not(.checkbox):not(.error) {
			font-weight: $label-font-weight;
			color: $label-text-color;
			@include margin(null null $label-margin-bottom);
			@include font-size($label-font-size);
		}
	}

}
